import { render, staticRenderFns } from "./AjaxMultiSelect.vue?vue&type=template&id=06d27f5a&"
import script from "./AjaxMultiSelect.vue?vue&type=script&lang=js&"
export * from "./AjaxMultiSelect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports