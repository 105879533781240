<template>
  <div>
    <k-basic-multi-select
      v-bind="$attrs"
      v-on="$listeners"
      :value="value"
      @input="$emit('input', $event)"
      :options="options"
      @search-change="SearchChange"
      :internal-search="false"
      :options-limit="100"
      :clear-on-select="true"
      :close-on-select="true"
      :loading="keresesLoading"
    >
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
        ><slot :name="slot" v-bind="scope"
      /></template>
    </k-basic-multi-select>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { NotificationFunctions } from '../../functions/NotificationFunctions';
import { timeout } from '../../utils/common';
export default {
  name: 'ajax-multi-select',
  inheritAttrs: false,
  data() {
    return {
      options: [],
      keresesLoading: false,
    };
  },
  created() {
    this.GetOptionsDebounced = debounce(e => {
      this.GetOptions(e);
    }, 500);
    this.GetOptions('');
  },
  mounted() {},
  beforeDestroy() {},
  methods: {
    async GetOptions(keresoszoveg) {
      this.keresesLoading = true;
      try {
        this.options = await this.getOptions(keresoszoveg);
      } catch (error) {
        this.options = [];
        NotificationFunctions.AjaxError({ error });
      }
      this.keresesLoading = false;
    },
    SearchChange(e) {
      if (!e) {
        return;
      }
      this.GetOptionsDebounced(e);
    },
  },
  computed: {},
  props: {
    value: {},
    getOptions: {
      typeof: Function,
      default: () => {
        return () => {
          return [];
        };
      },
    },
  },
};
</script>
